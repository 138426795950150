




















































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { SurveyModel } from "survey-vue";
import { VisualizationPanel } from "survey-analytics";

import sessionEvalVuexModule from "@/store/vuex-modules/admin-session-evaluations";
const evalStore = getModule(sessionEvalVuexModule);

import { transformDataToCsvUrl } from "@/services/csv";

interface FilterOption {
    name: string;
    count: number;
    id: string;
}

const defaultQuestions = [
    {
        type: "rating",
        name: "question1",
        title: "Please rate the quality of the CONTENT of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "rating",
        name: "question2",
        title: "Please rate the quality of the PRESENTATION of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "radiogroup",
        name: "question3",
        title:
            "Please rate the relevance of the session to you, your job and your company:",
        hideNumber: true,
        isRequired: true,
        choices: [
            "Extremely relevant",
            "Very relevant",
            "Somewhat relevant",
            "Little relevance",
            "Not relevant"
        ]
    },
    {
        type: "comment",
        name: "question4",
        hideNumber: true,
        title: "Any other comments?"
    }
];

export default Vue.extend({
    data() {
        return {
            activeSession: "",
            resultsPanel: null as VisualizationPanel | null
        };
    },
    computed: {
        responses(): Array<Record<string, any>> {
            return evalStore.responses ? evalStore.responses : [];
        },

        filteredResponses(): Array<Record<string, any>> {
            return this.activeSession
                ? this.responses.filter(
                      (resp) => resp.sessionId === this.activeSession
                  )
                : this.responses;
        },

        filterValues(): Array<FilterOption> {
            const retArr: Array<FilterOption> = [];

            retArr.push({
                name: "All Sessions",
                count: this.responses.length,
                id: ""
            });

            this.responses.forEach((resp) => {
                const current = retArr.find(
                    (item) => item.id === resp.sessionId
                );

                if (current) {
                    current.count++;
                } else {
                    retArr.push({
                        name: resp.sessionName
                            ? `${resp.sessionName} [${resp.sessionId}]`
                            : resp.sessionId,
                        id: resp.sessionId,
                        count: 1
                    });
                }
            });

            return retArr;
        },

        evalOptions(): Record<string, any> {
            return this.$store.getters.getPageOptions("sessionEvals");
        },

        questions(): Array<any> {
            return this.evalOptions &&
                Array.isArray(this.evalOptions.questions) &&
                this.evalOptions.questions.length
                ? this.evalOptions.questions
                : defaultQuestions;
        },

        evalsEnabled(): boolean {
            return this.evalOptions && "enabled" in this.evalOptions
                ? this.evalOptions.enabled
                : true;
        },

        userTableHeaders(): Array<string> {
            const staticHeaders = [
                "Attendee ID",
                "First Name",
                "Last Name",
                "Company",
                "Email",
                "Session ID",
                "Session Name"
            ];

            const dynamicHeaders = this.questions.map((question: any) =>
                question.title ? question.title : question.name
            );

            const final = [...staticHeaders, ...dynamicHeaders];

            return final;
        },

        userFlatData(): Array<Array<string>> {
            return this.filteredResponses.map((entry) => {
                const staticValues = [
                    entry.uid,
                    entry.firstName,
                    entry.lastName,
                    entry.companyName,
                    entry.email,
                    entry.sessionId,
                    entry.sessionName
                ];

                const dynamicValues = [] as Array<string>;

                this.questions.forEach((question: any) => {
                    const answersAsAny = entry.answers as any;
                    const answer = answersAsAny[question.name];

                    dynamicValues.push(answer ? answer : "");
                });

                return [...staticValues, ...dynamicValues];
            });
        },

        activeSessionName(): string {
            const active = this.filterValues.find(
                (value) => value.id === this.activeSession
            );

            return active ? active.name : "";
        },

        csvName(): string {
            return this.activeSession
                ? `session-evaluations-${Vue.prototype.MgSimpleSlugify(
                      this.activeSession
                  )}.csv`
                : "session-evaluations.csv";
        },

        csvUrl(): string {
            return this.getCsvUrl();
        }
    },

    watch: {
        activeSession() {
            this.refreshEvaluations();
        }
    },

    created() {
        this.refreshEvaluations();
    },

    methods: {
        async refreshEvaluations(): Promise<void> {
            await this.updateEvaluations();
            await this.initializeResults();
        },

        async updateEvaluations(): Promise<void> {
            await evalStore.getResponses();
        },

        async initializeResults(): Promise<void> {
            this.resultsPanel = null;
            const pollObj = {
                pollId: "",
                pages: [
                    {
                        name: "page1",
                        elements: this.questions
                    }
                ]
            };

            const survey = new SurveyModel(pollObj);
            const questions = survey.getAllQuestions();
            const answers = this.filteredResponses.map((resp) => resp.answers);

            const analyticsOptions = {
                haveCommercialLicense: true,
                allowDynamicLayout: false,
                allowHideQuestions: false
            };

            const dataTables = new VisualizationPanel(
                questions,
                answers,
                analyticsOptions
            );

            // filteredQuestions.forEach((question: Question) => {
            //     const visualizer = dataTables.getVisualizer(question.name);
            //     const validTypes = ["bar", "pie", "doughnut", "scatter"];
            //     const chartType =
            //         poll.chartType && validTypes.includes(poll.chartType)
            //             ? poll.chartType
            //             : "pie";
            //     /**
            //      * TODO: Fix explicit any. For some reason the VisualizerBase type this variable is set as throws
            //      * a property does not exist error, despite the fact that this works fine.
            //      */
            //     (visualizer as any).setChartType(chartType);
            // });

            this.resultsPanel = dataTables;

            const container = document.getElementById("pollResultsPanel");

            if (container) {
                container.innerHTML = "";
                this.resultsPanel.render(container);
            }
        },

        getCsvUrl(): string {
            const dataArray = [this.userTableHeaders, ...this.userFlatData];

            return transformDataToCsvUrl(dataArray);
        }
    }
});
