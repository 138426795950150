




























































import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsFavoriteItem } from "@/types/interfaces";

export default Vue.extend ({
    components: {
        Spinners
    },
    computed: {
        
        loading(): boolean {
            return analyticsStore.loading;
        },
    
        favorites(): Array<AnalyticsFavoriteItem> {
            return analyticsStore.favorites;
        },
    
        tableHeaders(): Array<string> {
            return ["Session ID", "Session Name", "Favorites"];
        },
    
        tableFooter(): Array<string> {
            return ["Total", "", `${this.totalHits}`];
        },
    
        totalHits(): number {
            return this.favorites.reduce((acc, curr) => {
                return (acc += curr.favorites);
            }, 0);
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        }
    },
    methods:{
        getData() {
            analyticsStore.getFavorites();
        },
    
        getCsvUrl() {
            const valuesOnly = this.favorites.map((item) => [
                item.id,
                item.title,
                item.favorites ? `${item.favorites}` : "0"
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly, this.tableFooter];
    
            return transformDataToCsvUrl(dataArray);
        }
    },

    mounted() {
        this.getData();
    }

})
