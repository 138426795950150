import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { SessionEvaluation } from "@/types/interfaces";
import Store from "../index";

// This module uses multiple endpoints that are related.
const evalEndpoint = "sessionEvals";

interface GetResponsesParams {
    sessionId?: string;
}

@Module({
    dynamic: true,
    store: Store,
    name: "AdminSessionEvalModule",
    namespaced: true
})
export default class AdminSessionEvalModule extends VuexModule {
    sessionId = "";
    responses: Array<SessionEvaluation> = [];

    @Mutation
    public setResponses(data: Array<SessionEvaluation>) {
        this.responses.splice(0, this.responses.length);
        data.forEach((item) => this.responses.push(item));
    }

    @Action({ commit: "setResponses" })
    async getResponses(sessionId = "") {
        const token = this.context.rootGetters.idToken;
        const params: GetResponsesParams = {};

        if (sessionId != "") {
            params.sessionId = sessionId;
        }

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(`/${evalEndpoint}`, params, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    let items: Array<SessionEvaluation> = [];

                    if (response && Array.isArray(response.data)) {
                        response.data.forEach((item) => {
                            items.push(item);
                        });
                    } else {
                        items = [];
                    }

                    return resolve(items);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
